import React from 'react';
import AuthUserContext from '../Session/AuthUserContext';
import { Link } from 'gatsby';

const UserPlaygroupListing = () => {  
  return (
    <AuthUserContext.Consumer>
      {authUser => 
        <ul>
          {Object.keys(authUser.playgroups).map(playgroupId =>
            <li><Link to={`/playgroup/${playgroupId}`}>{authUser.playgroups[playgroupId].name}</Link></li>
          )}
        </ul>
      }    
    </AuthUserContext.Consumer>
  )
}

export default UserPlaygroupListing;