import React, { Component } from 'react';

import Layout from '../components/layout';
import withAuthorization from '../components/Session/withAuthorization';
import UserPlaygroupListing from '../components/UserPlaygroupListing';

// const fromObjectToList = object =>
//   object
//     ? Object.keys(object).map(key => ({ ...object[key], index: key }))
//     : [];

class HomePageBase extends Component {
  render() {
    return (
      <React.Fragment>
        <h2>My playgroups</h2>
        <UserPlaygroupListing />
      </React.Fragment>
    );
  }
}

const authCondition = authUser => !!authUser;

const HomePage = withAuthorization(authCondition)(HomePageBase);

export default () => (
  <Layout>
    <HomePage />
  </Layout>
);
